<template>
  <v-container>
    <v-dialog v-model="show" persistent max-width="500">
      <v-card>
        <v-card-title class="headline"> {{ title }} </v-card-title>
        <v-card-text>
          {{ description }}
          <v-tooltip v-model="copied" top open-on-click>
            <template v-slot:activator="{ attrs }">
              <v-row>
                <v-text-field v-bind="attrs" v-show="secret" v-model="secret" readonly></v-text-field>
                <v-icon @click="clipboardSet">mdi-content-copy</v-icon>
              </v-row>
            </template>
            <span>Kopieret til clipboard</span>
          </v-tooltip>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="normal" text @click="show = false"> Luk </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      copied: false,
      show: true,
    };
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    secret: {
      type: String,
      required: true,
    },
  },

  methods: {
    ...mapMutations(["setSnackbar"]),

    async clipboardSet() {
      try {
        await navigator.clipboard.writeText(this.secret);
        this.copied = true;
      } catch (err) {
        this.setSnackbar({
          type: "error",
          msg: err.message,
          timeout: 10000,
        });
      }
    },
  },
};
</script>
